<template>
    <div class="align-items-start p-3 text-start">
        <nav aria-label="breadcrumb">
        <ol class="breadcrumb text-dark m-auto">
            <li class="breadcrumb-item active" aria-current="page">店家管理</li>
            <li class="breadcrumb-item active" aria-current="page">e指通特店管理</li>
            <li class="breadcrumb-item active" aria-current="page">e指通特店維護</li>
        </ol>
        </nav>
        <hr class="w-100">
        <Form @submit="save()" :validation-schema="schema" @invalid-submit="on_invalid_submit">
            <div class="col-12 row mx-auto data-form">
                <div class="mb-3">
                    <label for="web_site" class="form-label">網站連結</label>
                    <Field name="web_site" v-slot="{ meta, field }" v-model="tcpass_store_data.web_site">
                        <input
                            id="web_site"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            :disabled="$route.name === 'TcpassStoreManagementView'"
                        />
                    </Field>
                    <ErrorMessage name="web_site" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="introduce" class="form-label"><span class="required" v-if="$route.name !== 'TcpassStoreManagementView'">*</span>優惠項目</label>
                    <Field name="promotion_item" v-slot="{ meta, field }" v-model="tcpass_store_data.promotion_item">
                        <input
                            id="promotion_item"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            :disabled="$route.name === 'TcpassStoreManagementView'"
                        />
                    </Field>
                    <ErrorMessage name="promotion_item" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="introduce_en" class="form-label"><span class="required" v-if="$route.name !== 'TcpassStoreManagementView'">*</span>優惠內容</label>
                    <Field name="promotion_content" v-slot="{ meta, field }" v-model="tcpass_store_data.promotion_content">
                        <input
                            id="promotion_content"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            :disabled="$route.name === 'TcpassStoreManagementView'"
                        />
                    </Field>
                    <ErrorMessage name="promotion_content" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label class="form-label"><span class="required" v-if="$route.name !== 'TcpassStoreManagementView'">*</span>優惠期間</label>
                    <div class="form-check mb-3">
                        <input type="checkbox" class="form-check-input" style="float: none; vertical-align: middle; margin-top: 0px;" v-model="promotion_type_1" @change="onChangePromotionType1($event)" :disabled="$route.name === 'TcpassStoreManagementView'">
                        <label class="mx-2" style="vertical-align: middle;">自簽訂日起至112年12月31日止。</label>
                    </div>
                    <div class="form-check mb-3">
                        <input type="checkbox" class="form-check-input" style="float: none; vertical-align: middle; margin-top: 0px;" v-model="promotion_type_2" @change="onChangePromotionType2($event)" :disabled="$route.name === 'TcpassStoreManagementView'">
                        <label class="mx-2" style="vertical-align: middle;">其他：自簽訂日起至112年</label>
                        <span style="vertical-align: middle;">
                            <select id="month" class="form-select" style="display: inline; width: auto;" v-model="month" @change="onChangeMonth($event.target.value)" :disabled="$route.name === 'TcpassStoreManagementView'">
                                <option value="">請選擇</option>
                                <template v-for="item in month_option" :key="item">
                                    <option :value="item.value">{{ item.text }}</option>
                                </template>
                            </select>
                            月
                            <select id="date" class="form-select" style="display: inline; width: auto;" v-model="date" :disabled="$route.name === 'TcpassStoreManagementView'">
                                <option value="">請選擇</option>
                                <template v-for="item in date_option" :key="item">
                                    <option :value="item.value">{{ item.text }}</option>
                                </template>
                            </select>
                            日止。
                        </span>
                    </div>
                </div>
                <div class="mb-3" v-if="$route.name !== 'TcpassStoreManagementView'">
                    <label for="image" class="form-label"><span class="required" v-if="$route.name !== 'TcpassStoreManagementView'">*</span>店家商品圖片</label>
                    <input type="file" class="form-control" id="image" @change="file_selected" aria-describedby="image_help" accept=".jpg, .jpeg, .png" ref="image_input">
                    <div id="image_help" class="form-text">檔案大小不可超過 5 MB，圖片尺寸請提供800x600，檔案格式：png、jpeg、jpg</div>
                </div>
                <div class="mb-3">
                    <img v-if="tcpass_store_data.product_image && image == null" :src="tcpass_store_data.product_image" width="800" height="600" style="width: 100%;"/> 
                    <img v-if="image" :src="image" width="800" height="600" style="width: 100%;"/> 
                </div>
                <div class="mb-3">
                    <label class="form-label"><span class="required" v-if="$route.name !== 'TcpassStoreManagementView'">*</span>審核狀態</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <Field name="status_options" v-slot="{ meta, field }" v-model="tcpass_store_data.status">
                                <input
                                    id="status_radio_1"
                                    v-bind="field"
                                    class="form-check-input"
                                    :class="{
                                        'is-valid': meta.valid && meta.touched,
                                        'is-invalid': !meta.valid && meta.touched,
                                    }"
                                    type="radio"
                                    :value="1"
                                    :checked="tcpass_store_data.status === 1"
                                    :disabled="$route.name === 'TcpassStoreManagementView'"
                                />
                            </Field>
                            <label class="form-check-label" for="status_radio_1">審核通過(啟用)</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="status_options" id="status_radio_2" :value="2" v-model="tcpass_store_data.status" :disabled="$route.name === 'TcpassStoreManagementView'">
                            <Field name="status_options" v-slot="{ meta, field }" v-model="tcpass_store_data.status">
                                <input
                                    id="status_radio_2"
                                    v-bind="field"
                                    class="form-check-input"
                                    :class="{
                                        'is-valid': meta.valid && meta.touched,
                                        'is-invalid': !meta.valid && meta.touched,
                                    }"
                                    type="radio"
                                    :value="2"
                                    :checked="tcpass_store_data.status === 2"
                                    :disabled="$route.name === 'TcpassStoreManagementView'"
                                />
                            </Field>
                            <label class="form-check-label" for="status_radio_2">未通過(停用)</label>
                        </div>
                    </div>
                    <ErrorMessage name="status_options" class="radio-invalid-feedback" v-if="tcpass_store_data.status !== 0"/>
                </div>
                <div class="mb-3 mx-auto text-center">
                    <button type="submit" class="btn m-2 btn-primary" :disabled="button_disabled" v-if="$route.name !== 'TcpassStoreManagementView'">送出</button>
                    <button type="button" class="btn m-2 btn-danger" v-on:click="go_back()">返回</button>
                </div>
                <div class="mb-3">
                </div>
            </div>
        </Form>
    </div>
</template>

<script>
import { 
    get_tcpass_store_detail_admin,
    save_tcpass_store_detail_admin
} from '../../api/tcpass_store_admin';
import { mapActions, mapGetters } from 'vuex';
import { Field, Form, ErrorMessage } from 'vee-validate';
import { tcpass_store_admin_rules } from '../../rules/store/tcpass_store_admin';
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';

export default {
    components: {
        Field,
        Form,
        ErrorMessage,
    },
    data() {
        return {
            tcpass_store_data: {
                store_id: "",
                web_site: "",
                promotion_item: "",
                promotion_content: "",
            },
            month: "",
            date: "",
            agree: false,
            image: null,
            promotion_type_1: false,
            promotion_type_2: false,
            button_disabled: false,
            upload_image: null,
            month_option: [
                {"text": "1", "value": "1"},
                {"text": "2", "value": "2"},
                {"text": "3", "value": "3"},
                {"text": "4", "value": "4"},
                {"text": "5", "value": "5"},
                {"text": "6", "value": "6"},
                {"text": "7", "value": "7"},
                {"text": "8", "value": "8"},
                {"text": "9", "value": "9"},
                {"text": "10", "value": "10"},
                {"text": "11", "value": "11"},
                {"text": "12", "value": "12"},
            ],
            date_option: [],
            can_go_back: false,
        }
    },
    methods: {
        ...mapActions([
            'auth/UserLogout',
        ]),
        user_logout() {
            this['auth/UserLogout']().then(() => {
                this.$router.push({ name: 'Login' });
            }, () => {
                this.$router.push({ name: 'Login' });
            })
        },
        search() {
            get_tcpass_store_detail_admin({tcpass_store_id: this.$route.params.tcpass_store_id}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout()
                } else if (response.data.success === false && response.data.error_code === "1002") {
                    this.$router.push({ name: 'TcpassStoreManagementList' });
                } else if (response.data.success === false && response.data.error_code === "1003") {
                    this.$router.push({ name: 'TcpassStoreManagementList' });
                } else {
                    this.tcpass_store_data = response.data.response_data;
                    if (response.data.response_data.status !== undefined && response.data.response_data.status !== null) {
                        this.agree = true;
                    }
                    if (response.data.response_data.end_date !== undefined && response.data.response_data.end_date !== null) {
                        if (response.data.response_data.end_date === "2022-12-31") {
                            this.promotion_type_1 = true;
                        } else {
                            this.promotion_type_2 = true;
                            this.month = response.data.response_data.month;
                            this.onChangeMonth(response.data.response_data.month);
                            this.date = response.data.response_data.date;
                        }
                    }
                }
            })
        },
        onChangePromotionType1(event) {
            if (this.promotion_type_1 == true) {
                this.promotion_type_2 = false;
            }
        },
        onChangePromotionType2(event) {
            if (this.promotion_type_2 == true) {
                this.promotion_type_1 = false;
            }
        },
        file_selected(e) {
            if (e.target.files.length != 0) {
                if ((e.target.files.item(0).size / 1024 / 1024).toFixed(2) > 5) {
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            cancelButton: 'm-2 btn btn-danger'
                        },
                        buttonsStyling: false
                    })

                    swalWithBootstrapButtons.fire({
                        title: '錯誤訊息',
                        text: '檔案大小超過 5 MB 限制！',
                        icon: 'error',
                        cancelButtonText: '關閉',
                        showCancelButton: true,
                        showConfirmButton: false,
                        showCloseButton: true,
                        allowOutsideClick: false,
                    });
                    this.$refs.image_input.value = null;
                    this.image = null;
                    this.upload_image = null;
                } else {
                    const file = e.target.files.item(0);
                    const reader = new FileReader();
                    reader.addEventListener('load', this.image_loaded);
                    reader.readAsDataURL(file);
                    this.upload_image = e.target.files[0];
                }
            } else {
                this.$refs.image_input.value = null;
                this.image = null;
                this.upload_image = null;
            }
        },
        image_loaded(e) {
            this.image = e.target.result;
        },
        onChangeMonth(month) {
            this.date = "";
            this.date_option = [];
            if (month == 2) {
                this.date_option = [
                    {"text": "1", "value": "1"},
                    {"text": "2", "value": "2"},
                    {"text": "3", "value": "3"},
                    {"text": "4", "value": "4"},
                    {"text": "5", "value": "5"},
                    {"text": "6", "value": "6"},
                    {"text": "7", "value": "7"},
                    {"text": "8", "value": "8"},
                    {"text": "9", "value": "9"},
                    {"text": "10", "value": "10"},
                    {"text": "11", "value": "11"},
                    {"text": "12", "value": "12"},
                    {"text": "13", "value": "13"},
                    {"text": "14", "value": "14"},
                    {"text": "15", "value": "15"},
                    {"text": "16", "value": "16"},
                    {"text": "17", "value": "17"},
                    {"text": "18", "value": "18"},
                    {"text": "19", "value": "19"},
                    {"text": "20", "value": "20"},
                    {"text": "21", "value": "21"},
                    {"text": "22", "value": "22"},
                    {"text": "23", "value": "23"},
                    {"text": "24", "value": "24"},
                    {"text": "25", "value": "25"},
                    {"text": "26", "value": "26"},
                    {"text": "27", "value": "27"},
                    {"text": "28", "value": "28"},
                ];
            } else if (month == 1
                || month == 3
                || month == 5
                || month == 7
                || month == 8
                || month == 10
                || month == 12) {
                    this.date_option = [
                    {"text": "1", "value": "1"},
                    {"text": "2", "value": "2"},
                    {"text": "3", "value": "3"},
                    {"text": "4", "value": "4"},
                    {"text": "5", "value": "5"},
                    {"text": "6", "value": "6"},
                    {"text": "7", "value": "7"},
                    {"text": "8", "value": "8"},
                    {"text": "9", "value": "9"},
                    {"text": "10", "value": "10"},
                    {"text": "11", "value": "11"},
                    {"text": "12", "value": "12"},
                    {"text": "13", "value": "13"},
                    {"text": "14", "value": "14"},
                    {"text": "15", "value": "15"},
                    {"text": "16", "value": "16"},
                    {"text": "17", "value": "17"},
                    {"text": "18", "value": "18"},
                    {"text": "19", "value": "19"},
                    {"text": "20", "value": "20"},
                    {"text": "21", "value": "21"},
                    {"text": "22", "value": "22"},
                    {"text": "23", "value": "23"},
                    {"text": "24", "value": "24"},
                    {"text": "25", "value": "25"},
                    {"text": "26", "value": "26"},
                    {"text": "27", "value": "27"},
                    {"text": "28", "value": "28"},
                    {"text": "29", "value": "29"},
                    {"text": "30", "value": "30"},
                    {"text": "31", "value": "31"},
                ];
            } else if (month == 4
                || month == 6
                || month == 9
                || month == 11) {
                    this.date_option = [
                    {"text": "1", "value": "1"},
                    {"text": "2", "value": "2"},
                    {"text": "3", "value": "3"},
                    {"text": "4", "value": "4"},
                    {"text": "5", "value": "5"},
                    {"text": "6", "value": "6"},
                    {"text": "7", "value": "7"},
                    {"text": "8", "value": "8"},
                    {"text": "9", "value": "9"},
                    {"text": "10", "value": "10"},
                    {"text": "11", "value": "11"},
                    {"text": "12", "value": "12"},
                    {"text": "13", "value": "13"},
                    {"text": "14", "value": "14"},
                    {"text": "15", "value": "15"},
                    {"text": "16", "value": "16"},
                    {"text": "17", "value": "17"},
                    {"text": "18", "value": "18"},
                    {"text": "19", "value": "19"},
                    {"text": "20", "value": "20"},
                    {"text": "21", "value": "21"},
                    {"text": "22", "value": "22"},
                    {"text": "23", "value": "23"},
                    {"text": "24", "value": "24"},
                    {"text": "25", "value": "25"},
                    {"text": "26", "value": "26"},
                    {"text": "27", "value": "27"},
                    {"text": "28", "value": "28"},
                    {"text": "29", "value": "29"},
                    {"text": "30", "value": "30"},
                ];
            }
        },
        save() {
            this.button_disabled = true;
            let submit_tcpass_store_data = JSON.parse(JSON.stringify(this.tcpass_store_data));
            submit_tcpass_store_data.image = null;
            submit_tcpass_store_data.agree = this.agree;
            if (this.promotion_type_1 === true) {
                submit_tcpass_store_data.end_date = "2022-12-31";
            } else if (this.promotion_type_2 === true) {
                if (parseInt(this.month) < 10) {
                    submit_tcpass_store_data.end_date = "2022-" + "0" + this.month + "-" + this.date;
                } else {
                    submit_tcpass_store_data.end_date = "2022-" + this.month + "-" + this.date;
                }
            }
            let form_data = new FormData();
            form_data.append('tcpass_store_data', JSON.stringify(submit_tcpass_store_data));
            if (this.image !== null) {
                form_data.append('upload_image', this.upload_image, this.upload_image.name);
            }
            save_tcpass_store_detail_admin(form_data).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else {
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            confirmButton: 'm-2 btn btn-primary',
                        },
                        buttonsStyling: false
                    })
                    swalWithBootstrapButtons.fire({
                        title: '提示訊息',
                        text: response.data.message,
                        icon: 'success',
                        showCloseButton: true,
                        allowOutsideClick: false,
                        confirmButtonText: '確認',
                    }).then((result) => {
                        if (result.isConfirmed || result.dismiss === "close") {
                            this.$router.push({ name: 'TcpassStoreManagementList' });
                        }
                    })
                }
            }, error => {
                this.button_disabled = false;
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        cancelButton: 'm-2 btn btn-danger'
                    },
                    buttonsStyling: false
                })
                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: error.response.data.message,
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                })
            });
        },
        on_invalid_submit() {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    cancelButton: 'm-2 btn btn-danger'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: '錯誤訊息',
                text: '尚有欄位未填寫或是填寫錯誤！',
                icon: 'error',
                cancelButtonText: '關閉',
                showCancelButton: true,
                showConfirmButton: false,
                showCloseButton: true,
                allowOutsideClick: false,
            });
        },
        go_back() {
            if (this.can_go_back === true) {
                this.$router.back();
            } else {
                this.$router.push({name: "TcpassStoreManagementList"});
            }
        }
    },
    created() {
        window.scrollTo({top: 0, behavior: "smooth"});
        const router = useRouter();
        if (router.options.history.state.back !== null) {
            this.can_go_back = true;
        }
        if (this.$route.params.tcpass_store_id !== undefined) {
            this.search();
        }
    },
    computed: {
        ...mapGetters({
            role_list: 'role_list'
        }),
        schema() {
           return tcpass_store_admin_rules;
        },
    }
};
</script>
