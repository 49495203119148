import request from "../utils/request";

export function get_tcpass_store_list_admin(data: JSON){
    return request({
        url: "/getTcpassStoreListAdmin",
        method: "post",
        data: data
    });
}

export function get_tcpass_store_detail_admin(data: JSON){
    return request({
        url: "/getTcpassStoreDetailAdmin",
        method: "post",
        data: data
    });
}

export function save_tcpass_store_detail_admin(data: FormData){
    return request({
        url: "/saveTcpassStoreDetailAdmin",
        method: "post",
        data: data
    });
}